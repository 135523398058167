/* exported ready, triggerChange, DemUploader, DemModal, DemDependency, DemDate, DemTime, DemGrow, DemField */
'use strict';

/**
 * JS native alternative to jQuery(document).ready()
 *
 * @param   {Function} fn
 * @see     http://youmightnotneedjquery.com/
 */
function ready(fn) {
    if (document.readyState != 'loading'){
        fn();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', fn);
    } else {
        document.attachEvent('onreadystatechange', function() {
            if (document.readyState != 'loading')
                fn();
        });
    }
}

/**
 * Trigger a change event on the given element
 *
 * @param  {DOM} element
 * @param  {String} eventName - (optional)
 * @return void
 */
function triggerChange(element, eventName = 'change') {
    var event;

    if (document.createEvent) {
        event = document.createEvent('HTMLEvents');
        event.initEvent(eventName, true, true);
    } else {
        event = document.createEventObject();
        event.eventType = eventName;
    }

    event.eventName = eventName;

    if (document.createEvent) {
        element.dispatchEvent(event);
    } else {
        element.fireEvent('on' + event.eventType, event);
    }
}

class DemUploader {
    constructor(requiredConfig, optionalConfig) {
        this.options = {
            required: [
                'acceptedFiles',
                'formName',
                'identifier',
                'parent',
                'uploadType',
                'uploadUrl',
                'uuid',
                'wc',
            ],
            optional: [
                {
                    name: 'disableThumbnails',
                    default: false
                },
                {
                    name: 'DPI',
                    default: 72
                },
                {
                    name: 'existingData'
                },
                {
                    name: 'hookedElements',
                    default: []
                },
                {
                    name: 'multiple',
                    default: false
                },
                {
                    name: 'maxFileSize',
                    default: 67108864 // 64MB
                },
                {
                    name: 'required',
                    default: false
                }
            ]
        };

        // Assign class properties based on their respective config properties
        if (!requiredConfig) {
            console.warn('The following options are required:');
            console.warn(this.options.required);
            throw 'Class could not be constructed';
        } else {
            for (var option of this.options.required) {
                if (!requiredConfig[option]) {
                    throw `Required config option (${option}) not set.`;
                } else {
                    this[option] = requiredConfig[option];
                }
            }

            for (var opt of this.options.optional) {
                this[opt.name] = (optionalConfig[opt.name] ? optionalConfig[opt.name] : eval(opt.default));
            }
        }

        // Convert to array
        var tmp = this.acceptedFiles;
        this.acceptedFiles = {};
        this.acceptedFiles.str = tmp;
        this.acceptedFiles.arr = tmp.split(', ');

        // FIX: Ensure that all jpeg extensions are included, when either extension is included
        if (this.acceptedFiles.arr.includes('.jpg')) {
            this.acceptedFiles.arr.push('.jpeg');
        } else if (this.acceptedFiles.arr.includes('.jpeg')) {
            this.acceptedFiles.arr.push('.jpg');
        }

        this.createDefaultElements();

        // Initialize listeners
        this.addListeners();

        this.addExistingData();

        this.requiresValidation = eval(optionalConfig.requiresValidation);
    }


    /**
     * =======================================
     * Listeners
     * =====
     */

    /**
     * Adds event listeners on upload area and hidden input
     */
    addListeners() {
        this.dropzone.addEventListener('dragenter', e => this.onHover(e), false);
        this.dropzone.addEventListener('dragleave', () => this.changeState(null), false);
        this.dropzone.addEventListener('drop', e => this.onDrop(e), false);
        this.hiddenInput.addEventListener('change', e => this.onDrop(e), false);
        window.addEventListener('dragover', e => this.onDrag(e), false);
        window.addEventListener('drop', e => this.onDrag(e) , false);


        setTimeout(() => {
            this.sizeElement = document.querySelector('*[name*="size"]');
            if (this.sizeElement && this.sizeElement.tagName == 'SELECT') {
                this.sizeElement.addEventListener('change', e => this.onSelectChange(e), false);
                var tmpEvent = new Event('change');
                this.sizeElement.dispatchEvent(tmpEvent);
            }
        }, 100);
    }


    /**
     * Triggers "hover" element style
     * @param  {Event} e - File dragged onto upload area
     */
    onHover() {
        if (this.multiple || this.fileInfo.children.length == 0) {
            this.changeState('hover');
        }
    }


    /**
     * Triggers element style change, renders a file preview, and initiates file upload
     * @param  {Event} e - File "dropped" on upload area
     */
    onDrop(e) {
        // Looks for file in "drop" event data and hidden input data
        var files = (e.dataTransfer ? e.dataTransfer.files : this.hiddenInput.files);

        // Prevents too many files from being uploaded
        if (this.multiple || (this.fileInfo.children.length == 0 && files.length == 1)) {
            this.changeState('dropped');

            for (var i = files.length - 1; i >= 0; i--) {
                // Checks file size before uploading
                if (files[i].size > this.maxFileSize) {
                    this.triggerError(`Exceeds max file size (${this.maxFileSize/1024/1024} MB)`, files[i].name, false);

                    /* @todo: Reset all upload__area elements if drop fails */

                } else if (!this.validateFileExtension(files[i].name, this.acceptedFiles.arr)) {
                    this.triggerError('Unaccepted file type', files[i].name, false);
                } else {
                    this.createFileInfoElement(files[i].name, this.uuid);
                    this.requestUpload(files[i]);
                }
            }
        } else {
            this.triggerError('Multiple files are not allowed', null, true);
        }
    }


    /**
     * Prevents default behavior (like displaying "drop/add" cursor effect)
     * @param  {Event} e - File dragged over window
     */
    onDrag(e) {
        e.stopPropagation();
        e.preventDefault();

        if ((this.multiple || this.fileInfo.children.length == 0) && !this.dropzone.classList.contains('uploader__area--error')) {
            this.changeState('drag');
        }
    }


    /**
     * Breaks apart the selected option inner html, like (18W x 24H) --> ['18W', '24H'] --> 18, 24
     * @param  {Event} e - Config-defined size elements are changed
     */
    onSelectChange() {
        if (this.tmpFileData) {
            var validateResponse = this.validateImage(this.tmpFileData.tests, this.tmpFileData.name);
            if (validateResponse) {
                this.triggerInfo('The uploaded image now meets size requirements', 'Success', this.tmpFileData.name);
                this.parent.getElementsByClassName('uploader__preview__title--error')[0].classList.remove('uploader__preview__title--error');
            }
        }
    }


    /**
     * Toggles a highlight on corresponding thumbnail
     * @param  {Event} e - Mouse enters or exits fileInfo element
     * @param  {Boolean} remove - Whether or not to turn off highlight
     */
    onFileHover(e, remove = false) {
        var uuid = e.target.dataset.uuid,
            thumb = document.querySelectorAll(`a[data-uuid="${uuid}"]`)[0];

        if (thumb) {
            if (!remove) {
                thumb.classList.add('thumb--highlight');
            } else {
                thumb.classList.remove('thumb--highlight');
            }
        }
    }


    /**
     * Adjusts progress bar width to XHR completion percentage
     * @param  {Event} e - XHR upload
     */
    onRequestProgress(e) {
        if (e.lengthComputable) {
            // Evaluate percent complete
            var percentage = Math.round((e.loaded * 100) / e.total);

            // Update dropzone progress bar to reflect current percentage
            this.progressBar.style.width = percentage + '%';
            this.progressBar.dataset.complete = percentage;

            // Indicate that the server is processing (after upload completes)
            if (percentage == 100) {
                this.changeState('processing');
            }
        }
    }


    /**
     * Updates the DOM to either accept a new file for uploading OR prevent further uploads
     * @return {undefined}
     */
    onRequestComplete() {
        var xhr = this.xhr;

        if (xhr.readyState == xhr.DONE && xhr.status > 0) {
            try {
                var response = JSON.parse(xhr.response);
            } catch(e) {
                console.error(xhr.response);
            }

            // If request was successful
            if (xhr.status == 200 && response instanceof Object) {
                this.parseResponse(response);

                if (!this.multiple) {
                    this.changeState('complete');
                }

                this.changeMessage('Upload complete');
                this.progressBar.classList.remove('uploader__area__progress--processing');
            } else {
                // If request failed
                this.progressBar.style.width = 0;
                if (response instanceof Object) {
                    this.triggerError(`Server Error ${xhr.status}: ${response['error']}`, null, true);
                } else {
                    this.triggerError(`Server Error: See console for details`, null, true);
                }

                const uuid = this.uuid;
                const li = document.querySelector(`[data-uuid="${uuid}"]`);

                this.deleteElement(li);
            }
        }
    }


    /**
     * Update DOM after an aborted request
     * @return {undefined}
     */
    onRequestAbort() {
        const uuid = this.uuid;
        const li = document.querySelector(`[data-uuid="${uuid}"]`);

        // Reset progress bar
        this.progressBar.style.width = 0;

        // Reset state
        this.changeState();

        this.deleteElement(li);
    }




    /**
     * =======================================
     * DOM Actions
     * =====
     */

    /**
     * [createDefaultElements description]
     * @return {[type]} [description]
     */
    createDefaultElements() {

        // Init dropzone
        this.dropzone = this.parent.querySelector('.uploader__area');

        // =======================================
        // Label <label>
        // =======================================
        var label = this.parent.querySelector('label.uploader__area');
        label.setAttribute('for', `uploader__input_${this.identifier}`);

        // Progress Bar
        var progressBar = document.createElement('div');
        progressBar.className = 'uploader__area__progress';
        label.appendChild(progressBar);
        this.progressBar = progressBar;

        // Message
        var text = document.createTextNode('Click or drag to upload files');
        var message = document.createElement('span');
        message.appendChild(text);
        message.className = 'uploader__area__message';
        label.appendChild(message);
        this.dropzoneMessage = message;


        // =======================================
        // Preview Area <ul>
        // =======================================
        var ul = document.createElement('ul');
        ul.className = 'uploader__preview';
        this.parent.insertBefore(ul, label.nextSibling);
        this.fileInfo = ul;


        // =======================================
        // File Input <input>
        // =======================================
        var input = document.createElement('input');
        input.setAttribute('id', 'uploader__input_' + this.identifier);
        input.className = 'uploader__input';
        input.setAttribute('type', 'file');
        input.setAttribute('accept', this.acceptedFiles.arr);
        input.setAttribute('capture', '');
        // var capture = document.createAttribute('capture');
        // input.setAttributeNode(capture);
        this.parent.appendChild(input);
        this.hiddenInput = input;


        // =======================================
        // Hidden Input <input>
        // =======================================
        var hiddenInput = document.createElement('input');
        hiddenInput.className = 'uploader__input__form';
        if (this.required) {
            hiddenInput.classList.add('required-entry');
        }

        if (!this.formName.includes('[uuid]')) {
            this.formName += '[uuid]';
        }
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', this.formName);
        this.parent.appendChild(hiddenInput);
        this.hiddenUUIDInput = hiddenInput;
    }


    /**
     * Appends a new list item with the file name and editing controls
     * @param  {String} name - Original file name
     * @param  {String} uuid
     */
    createFileInfoElement(name, uuid) {
        // Create <li> for file
        var li = document.createElement('li');
        li.className = 'up-file';
        li.dataset.uuid = uuid;

        // Insert the file's original name
        var nameElement = document.createElement('span');
        nameElement.appendChild(document.createTextNode(name));
        nameElement.className = 'uploader__preview__title';
        li.appendChild(nameElement);

        // Insert a '×' button to trigger removal
        var btn = document.createElement('i');
        btn.className = 'uploader__remove-btn';
        btn.setAttribute('title', 'Remove file');
        btn.addEventListener('click', e => this.removeFile(e), false);
        li.appendChild(btn);

        // Add <li> to document
        this.fileInfo.appendChild(li);

        li.addEventListener('mouseenter', e => this.onFileHover(e), false);
        li.addEventListener('mouseleave', e => this.onFileHover(e, true), false);
    }


    /**
     * Adds metadata to fileInfoElement
     * @param {String} uuid
     * @param {Array} metadata
     */
    addFileMetadata(uuid, fileMetadata) {
        var fileInfoElement = this.parent.querySelectorAll('li[data-uuid="' + uuid + '"]')[0];

        for (var data of fileMetadata) {
            // Define possible metadata
            var title = data['title'],
                content = data['content'];

            // Create metadata element
            var metadata = document.createElement('small');
            metadata.className = 'uploader__preview__metadata';

            // Insert title
            if (title) {
                var titleElement = document.createElement('strong');
                titleElement.appendChild(document.createTextNode(title + ' '));
                metadata.appendChild(titleElement);
            }

            // Insert content
            if (content) {
                metadata.appendChild(document.createTextNode(content));
            }

            // Append metadata element to fileInfoElement
            fileInfoElement.appendChild(metadata);
        }
    }


    /**
     * Populates product page with existing upload data if editing item from cart
     */
    addExistingData() {
        if (this.existingData) {
            this.uuid = this.existingData.uuid;
            this.hiddenUUIDInput.setAttribute('value', this.uuid);
            this.createFileInfoElement(this.existingData.name, this.existingData.uuid);
            this.addFileMetadata(this.existingData.uuid, this.existingData.metadata);
            this.changeState('hidden');

            document.addEventListener('DOMContentLoaded', () => {
                // Note: Thumbnail images cannot be accessed until document is fully loaded
                this.addThumbnail(this.existingData.url.preview, this.existingData.url.thumbnail);
            });
        }
    }


    /**
     * Update text inside upload area
     * @param {String} message
     */
    changeMessage(message) {
        if (this.dropzoneMessage.innerHTML !== message) {
            this.dropzoneMessage.innerHTML = message;
        }
    }


    /**
     * Add a thumbnail
     * @param {String} url - URL to preview image
     */
    addThumbnail(previewUrl, thumbnailUrl) {
        var thumbnailCollection = document.querySelectorAll('ul.thumbnails')[0];

        if (!thumbnailCollection) {
            var tmpContainer = document.createElement('li');
            tmpContainer.className = 'span2';

            var container = document.querySelectorAll('ul.thumbnails')[0];
            container.appendChild(tmpContainer);

            // Re-runs to get newly created element
            thumbnailCollection = document.querySelectorAll('ul.thumbnail')[0];
        }


        // Time query reduces caching bugs
        var timeQuery = '?' + new Date().getTime();
        previewUrl += timeQuery;
        thumbnailUrl += timeQuery;

        // Create thumbnail elements (these imitate Magento 1.6 thumbnail elements)
        var li = document.createElement('li');
        li.className = 'span2';
        li.dataset.uuid = this.uuid;

        var a = document.createElement('a');
        a.className = 'thumbnail swap-image';
        a.setAttribute('onclick', 'swapImage(this); return false;');
        a.setAttribute('href', previewUrl);
        a.dataset.uuid = this.uuid;

        var img = document.createElement('img');
        img.setAttribute('src', thumbnailUrl);
        img.dataset.uuid = this.uuid;

        // Add thumbnail to thumbnail container
        a.appendChild(img);
        li.appendChild(a);
        thumbnailCollection.appendChild(li);
        a.click();
    }


    /**
     * Change upload area CSS classes based on the given state
     * @param {String} state
     */
    changeState(state) {
        var upClassList = this.dropzone.classList;

        switch (state) {
        case 'hover':
            upClassList.add('uploader__area--hover');
            break;

        case 'drag':
            this.changeMessage('Drop file here to upload');
            upClassList.add('uploader__area--drag');
            break;

        case 'dropped':
            this.changeMessage('Uploading file...');
            upClassList.add('uploader__area--dropped');
            upClassList.remove(
                'uploader__area--hover',
                'uploader__area--drag'
            );
            break;

        case 'processing':
            this.changeMessage('Processing upload...');
            this.progressBar.classList.add('uploader__area__progress--processing');
            break;

        case 'complete':
            this.changeState('hidden');
            // this.changeMessage('Upload complete');
            // this.dropzone.setAttribute("disabled", true);
            // this.dropzone.setAttribute("title", "Max file limit reached");
            // this.progressBar.style.width = 100 + "%";
            // this.progressBar.dataset.complete = 100;
            break;

        case 'error':
            var preview = this.parent.getElementsByClassName('uploader__preview__title')[0];

            if (preview) {
                preview.classList.add('uploader__preview__title--error');
            }

            this.progressBar.style.width = 0;
            break;

        case 'hidden':
            this.changeState(null);
            upClassList.add('uploader__area--hidden');
            break;

        case null:
        default:
            this.changeMessage('Click or drag to upload files');
            this.parent.classList.remove('uploader--error');
            upClassList.remove(
                'uploader__area--hover',
                'uploader__area--complete',
                'uploader__area--drag',
                'uploader__area--dropped',
                'uploader__area--error',
                'uploader__area--hidden'
            );

            // Remove files
            this.hiddenInput.value = null;

            this.dropzone.removeAttribute('disabled');
            this.dropzone.removeAttribute('title');

            this.progressBar.classList.remove('uploader__area__progress--processing');
            this.progressBar.style.width = 0;

            break;
        }
    }


    /**
     * Triggers an "error" state by adjusting class, message, and optionally resetting
     * @param {String}  message
     * @param {Boolean} reset
     */
    triggerError(message, name = null, reset = false) {
        new DemModal('error', message, null, name);
        this.disableHookedElements();

        this.changeState('error');

        if (reset) {
            this.changeState(null);
        }
    }


    /**
     * Triggers a "warning" modal
     * @param  {String} message
     * @param  String} name
     */
    triggerWarning(message, name = null) {
        new DemModal('warning', message, null, name);
    }


    /**
     * Triggers an "Info" modal
     * @param  {String} message
     * @param  {String} title (optional) - A title to replace the default one (Info)
     * @param  {String} name (optional) - An associated filename
     */
    triggerInfo(message, title = null, name = null) {
        new DemModal('info', message, title, name);
    }


    /**
     * Removes 'disabled' flag from hooked elements
     */
    enableHookedElements() {
        this.hookedElements.forEach((el) => {
            el.removeAttribute('disabled');
        });
    }


    /**
     * Adds 'disabled' flag to hooked elements
     */
    disableHookedElements() {
        this.hookedElements.forEach((el) => {
            el.setAttribute('disabled', true);
        });
    }


    /**
     * Updates DOM based on response data
     * @param  {Object} response - XHR response
     */
    parseResponse(response) {
        if (typeof response !== 'object') {
            // Ensures that response is always handled as an Object
            response = JSON.parse(response);
        }

        // Update thumbnails if enabled & the response has a url
        if (!this.disableThumbnails && response.url) {
            this.addThumbnail(response.url.preview, response.url.thumbnail);
        }

        if (response.metadata) {
            this.addFileMetadata(response.uuid, response.metadata);
        }

        if (this.requiresValidation && !response['tests']['zip']) {
            // Prepares for validation by storing test variables
            this.tmpFileData = {
                'tests': response['tests'],
                'name': response['name']
            };

            this.validateImage(response['tests'], response['name']);
        } else if (this.requiresValidation && response['tests']['zip']) {
            // If validation is required, but file is a .zip
            this.enableHookedElements();
        }
    }


    /**
     * Removes file <li> from file info & sends a delete request to server
     * @param  {Event} e - Click on remove "X"
     */
    removeFile(e) {
        var fileName = e.target.parentNode.childNodes[0].textContent,
            conf = false;

        if (this.xhr.readyState != XMLHttpRequest.DONE) {
            return this.cancelRequest();
        } else {
            conf = confirm('Are you sure you want to remove "' + fileName +  '"?');
        }


        if (conf) {
            // Use "delayed" removal queue (prevents files from being deleted too soon)
            if (window.location.href.includes('checkout/cart/configure')) {
                if (!this.fileRemovalQueue) {
                    this.fileRemovalQueue = [];
                }

                this.fileRemovalQueue.push(e.target.parentNode.dataset.uuid);

                // Triggers delete requests for all files stored in fileRemovalQueue
                var addToCartButton = document.getElementsByClassName('add_to_cart_button')[0];
                addToCartButton.addEventListener('click', e => this.forceRemoveFiles(e), false);

                // Trigger an info modal
                this.triggerInfo(`"${fileName}" was queued for deletion`, 'File marked for deletion');
            } else {
                // Delete file from server
                this.requestDelete(e.target.parentNode.dataset.uuid);

                // Trigger an info modal
                this.triggerInfo(`"${fileName}" was deleted successfully`, 'File deleted');
            }


            // Remove fileInfo element
            var fileInfoElement = e.target.parentElement;
            this.deleteElement(fileInfoElement);

            // Remove thumbnail
            var thumbnailElement = document.querySelectorAll('li[data-uuid="' + this.uuid + '"')[0];

            if (thumbnailElement) {
                this.deleteElement(thumbnailElement);
            }

            if (document.querySelectorAll('a.thumbnail')[0]) {
                document.querySelectorAll('a.thumbnail')[0].click();
            }

            this.hiddenUUIDInput.removeAttribute('value');

            // Clear temporary data
            delete this.tmpFileData;

            if (this.fileInfo.children.length == 0) {
                // Reset dropzone
                this.changeState(null);
            }
        }

    }


    /**
     * Sends an XHR delete request for every UUID stored in fileRemovalQueue
     * @param  {Event} e - Click on the "update cart" btn ('.add_to_cart')
     */
    forceRemoveFiles() {
        if (document.getElementsByClassName('help-inline').length == 0) {
            for (var uuid of this.fileRemovalQueue) {
                this.requestDelete(uuid);
            }
        }
    }




    /**
         * =======================================
         * HTTP Requests
     * =====
     */

    /**
     * Creates and sends an XHR to upload a file
     * @param  {Object} file
     */
    requestUpload(file) {
        var xhr = new XMLHttpRequest();
        this.xhr = xhr;

        // Define XHR listeners
        xhr.upload.addEventListener('progress', e => this.onRequestProgress(e), false);
        xhr.addEventListener('readystatechange', e => this.onRequestComplete(e), false);
        xhr.addEventListener('abort', e => this.onRequestAbort(e), false);

        // Store UUID in hiddenInput (for future reference in cart, orders, etc)
        this.hiddenUUIDInput.setAttribute('value', this.uuid);

        // Open request
        xhr.open('POST', this.uploadUrl);

        // Build request
        var data = new FormData();
        data.append('upload[]', file, file.name);
        data.append('upload_type', this.uploadType);
        data.append('wc', this.wc);
        data.append('uuid', this.uuid);

        // Include data for testing lfmedia
        data.append('testData', this.testData);

        xhr.send(data);
    }


    /**
     * Creates and sends an XHR to delete a file based on its UUID
     * @param  {String} uuid
     */
    requestDelete(uuid) {
        var xhr = new XMLHttpRequest();
        this.xhr = xhr;

        // Initialize request
        xhr.open('POST', this.uploadUrl);

        // Build request
        var data = new FormData();
        data.append('upload_type', this.uploadType);
        data.append('wc', this.wc);
        data.append('uuid', this.uuid); // needed for server's validation of this request
        data.append('delete', uuid);

        xhr.send(data);
    }


    /**
     * Cancel the current XHR
     * @return {undefined}
     */
    cancelRequest() {
        const xhr = this.xhr;

        if (xhr instanceof XMLHttpRequest && xhr.readyState != xhr.DONE) {
            xhr.abort();
            new DemModal('info', 'File upload was aborted', 'Upload cancelled');
        }
    }



    /**
     * =======================================
     * Helpers
     * =====
     */

    /**
     * Checks if string contains valid extension
     * @param  {String} name - Filename or extension to be validated
     * @param  {Array} extensions - An array of acceptable extensions
     * @return {Boolean}
     */
    validateFileExtension(name, extensions) {
        // Check for "all file extension" symbols
        if (extensions[0] == '.' || extensions[0] == '*' || extensions[0] == '*.' || extensions[0] == '*.*') {
            return true;
        }

        // Does filename have an allowed extension?
        if (name.includes('.')) {
            var nameParts = name.split('.').map(ext => ext.toLowerCase());
            var nameExt = nameParts[nameParts.length - 1];

            for (var ext of extensions) {
                ext = ext.toLowerCase();
                if (ext.includes(nameExt)) {
                    return true;
                }
            }
        }

        return false;
    }


    /**
     * Evaluates whether or not
     * @param  {Array} tests - A collection of measurements to evaluate against current requirements
     * @return {Boolean}
     */
    validateImage(tests, fileName) {
        var valid = false;

        // Define images values to test
        var DPI = tests['dpi'],
            width = Math.round(tests['width'] / DPI),
            height = Math.round(tests['height'] / DPI);

        // Define requirement values
        var reqs = this.sizeRequirements,
            reqDPI = reqs['dpi'],
            reqWidth = reqs['width'],
            reqHeight = reqs['height'];

        // Test for errors (error --> prevents upload)
        if (DPI < reqDPI && !tests['vector']) {
            this.triggerError(`Minimum ${reqDPI} DPI required`, fileName);
        } else if (width - reqWidth >= .5) {
            this.triggerError(`Too wide (${width}in). Max bleed is 0.5 in`, fileName);
        } else if (height - reqHeight > .5) {
            this.triggerError(`Too tall (${height}in). Max bleed is 0.5 in`, fileName);
        } else if (width - reqWidth < -0.25) {
            this.triggerError(`Width must be at least ${reqWidth}in`, fileName);
        } else if (height - reqHeight < -0.25) {
            this.triggerError(`Height must be at least ${reqHeight}in`, fileName);
        } else {
            valid = true;

            // Test for warnings (warning --> allows upload)
            if (DPI / reqDPI >= .9 && DPI / reqDPI < 1 ) {
                this.triggerWarning(`${reqDPI} DPI recommended`, fileName);
            } else if (width - reqWidth >= -0.25 && width - reqWidth < 0) {
                this.triggerWarning('Width is slightly smaller than recommended', fileName);
            } else if (height - reqHeight >= -0.25 && height - reqHeight < 0) {
                this.triggerWarning('Height is slightly smaller than recommended', fileName);
            } else if (width === reqWidth) {
                this.triggerWarning('No bleed detected on width', fileName);
            } else if (height === reqHeight) {
                this.triggerWarning('No bleed detected on height', fileName);
            }
        }

        if (valid) {
            this.enableHookedElements();
        }

        return valid;
    }

    /**
     * Get size requirements (dimensions) from LFMedia
     *
     * @return {Object}
     */
    get sizeRequirements() {
        /* globals LFMedia */
        if (!(LFMedia instanceof Object)) {
            throw 'LFMedia global var is missing';
        }

        var lfmediaData = LFMedia.sfa;

        return {
            width: lfmediaData.x,
            height: lfmediaData.y,
            dpi: this.DPI
        };
    }


    /**
     * Removes element from DOM
     * @param  {HTMLElement} element - DOM element
     */
    deleteElement(element) {
        if (element instanceof HTMLElement) {
            element.parentNode.removeChild(element);
        }
    }
}

class DemModal {
    /**
     * @param  {String}  type
     * @param  {String}  message
     * @param  {String}  title
     * @param  {String}  name
     * @param  {String}  color
     */
    constructor(type, message, title, name, color) {
        try {
            this.id = 'DemModal_' + Math.random();
            this.type = type.toLowerCase();
            this.customTitle = (title ? title : null);
            this.name = name;
            this.skrim = (this.type == 'error' ? true : false),
            this.message = message;
            this.color = (color) ? `style="color: ${color}"` : '';
        } catch (e) {
            console.error(e);
        }

        this.init();
    }

    init() {
        var typeClass = null;
        var title = '';

        switch (this.type) {
        case 'warning':
            typeClass = 'DemModal--warning';
            title = 'Warning';
            break;
        case 'error':
            typeClass = 'DemModal--error';
            title = 'Error';
            break;
        case 'success':
            typeClass = 'DemModal--success';
            title = 'Success';
            break;
        case 'info':
        default:
            if (this.customTitle) {
                typeClass = 'DemModal--info';
                title = this.customTitle;
            } else {
                new DemModal('error', 'A third parameter (title) is required for "info" modals');
                throw 'Exception A third parameter (title) is required for "info" modals';
            }

            break;
        }

        // Enables custom titles for all modal types
        if (this.customTitle) {
            title = this.customTitle;
        }

        /* @todo: Arrange existing modals in a vertical "queue" for visual organization */

        var fileName = '';
        if (this.name !== null ) {
            fileName = `<br><span class="DemModal__name">${this.name}</span>`;
        }

        this.template = `<div id="${this.id}" class="DemModal ${typeClass}"><div class="DemModal__message"><h3 class="DemModal__alert" ${this.color}>${title}</h3>${this.message}${fileName}</div><i class="DemModal__close">&times;</i><!--<div class="DemModal__skrim">--></div></div>`;
        this.render(this.template, true);

        if (this.skrim) {
            var bgSkrim = document.createElement('div');
            bgSkrim.className = 'DemModal__bgSkrim';
            document.body.appendChild(bgSkrim);
        }

        this.addListeners();
    }

    render(html) {
        this.appendHtml(document.body, html);
    }

    appendHtml(el, str) {
        var div = document.createElement('div');
        div.innerHTML = str;
        while (div.children.length > 0) {
            el.appendChild(div.children[0]);
        }
    }

    remove() {
        var modal = document.getElementById(this.id);
        if (modal) {
            modal.classList.add('DemModal--hidden');

            setTimeout(() => {
                modal.parentNode.removeChild(modal);
            }, 510);
        }

        var skrim = document.getElementsByClassName('DemModal__bgSkrim')[0];
        if (skrim) {
            skrim.classList.add('DemModal__bgSkrim--hidden');

            setTimeout(() => {
                skrim.parentNode.removeChild(skrim);
            }, 510);
        }
    }

    addListeners() {
        var removeBtn = document.getElementById(this.id).getElementsByClassName('DemModal__close')[0];
        removeBtn.addEventListener('click', e => this.remove(e), false);
    }
}

/**
 * @link http://api.jqueryui.com/datepicker/
 * @link https://api.jqueryui.com/datepicker/#option-minDate
 */
class DemDate {
    /* global jQuery */
    /**
     * @constructor
     * @param  {HTMLElement} element
     * @param  {String} dateFormat
     * @param  {String} dateOffset
     * @param  {(Object|NULL)} options
     * @return {DemDate}
     */
    constructor(element, dateFormat = 'm/d/yy', options = null) {
        if (!(element instanceof HTMLElement)) {
            console.error('`element` must be an instance of HTMLElement');
            return;
        } else if (!window.jQuery) {
            console.error('DemDate requires jQuery');
            return;
        }

        this.element = element;
        this.dateFormat = dateFormat;
        this.options = options;

        this.createDatepicker();

        return this;
    }

    /**
     * Create jQuery datepicker
     * @return {undefined}
     */
    createDatepicker() {
        var el = this.element;

        // Create config for jQuery options
        var config = {
            dateFormat: this.dateFormat,
            onSelect() {
                var event = document.createEvent('HTMLEvents');
                event.initEvent('change', true, false);
                el.dispatchEvent(event);
            }
        };


        // Add options to config
        if (this.options instanceof Object) {
            for (var prop in this.options) {
                config[prop] = this.options[prop] || null;
            }
        }

        // Create datepicker with config object
        jQuery(el).datepicker(config);

        // Format original element
        el.setAttribute('readonly', true);
        el.setAttribute('placeholder', 'Click to set date');
        el.style.background = 'white';
        el.style.cursor = 'pointer';
    }
}


class DemTime {
    /**
     * Create DemTime object
     * @param  {HTMLElement} element
     * @param  {String} timeStringLength
     * @return {DemTime}
     */
    constructor(element, timeStringLength = 'short') {
        this.element = element;
        this.timeStringLength = timeStringLength;
        this.timeInputLength = (this.timeStringLength == 'long') ? 5 : 2;
        this.parentContainer = element.parentNode.parentNode;
        if (!this.element) {
            console.error('Element not defined');
            return;
        }

        this.init();

        return this;
    }


    /**
     * Initialize time elements
     *
     * @return {undefined}
     */
    init() {
        this.parentContainer.style.position = 'fixed';
        this.parentContainer.style.opacity = 0;
        this.parentContainer.style.zIndex = -1;

        this.createTimeElements();

        if (this.element.value.length > 0) {
            this.insertExistingData();
        }
    }


    /**
     * Create time elements
     *
     * @return {undefined}
     */
    createTimeElements() {
        var timePlaceholder = (this.timeStringLength == 'long') ? '5:00' : 7;
        var requiredClass = (this.element.classList.contains('required-entry')) ? 'required-entry' : '';

        var controlsBody = `
            <div class="control-group">
                <label>Start Time:</label>
                <div class="btn-toolbar">
                    <input type="text" maxlength="${this.timeInputLength}" class="${requiredClass}" style="width: 40px; margin-bottom: 0; margin-right: 4px; vertical-align: top;" placeholder="${timePlaceholder}">
                    <div class="btn-group">
                        <a class="btn active" style="z-index: 1;">AM</a>
                        <a class="btn" style="z-index: 1;">PM</a>
                    </div>
                </div>
            </div>
            <div class="control-group">
                <label>End Time:</label>
                <div class="btn-toolbar">
                    <input type="text" maxlength="${this.timeInputLength}" class="${requiredClass}" style="width: 40px; margin-bottom: 0; margin-right: 4px; vertical-align: top;" placeholder="${timePlaceholder}">
                    <div class="btn-group">
                        <a class="btn active" style="z-index: 1;">AM</a>
                        <a class="btn" style="z-index: 1;">PM</a>
                    </div>
                </div>
            </div>`;

        var controls = document.createElement('div');
        controls.classList.add('timeStartEndControls');
        controls.innerHTML = controlsBody;

        this.parentContainer.parentNode.appendChild(controls);
        this.controls = controls;
        this.activateListeners();
    }


    /**
     * Add listeners for user interactions on time elements
     *
     * @return {undefined}
     */
    activateListeners() {
        var clickElements = this.controls.querySelectorAll('.control-group a');
        for (var btn of clickElements) {
            btn.addEventListener('click', (e) => this.onClick(e), false);
        }

        var keypressElements = this.controls.querySelectorAll('input, a');
        for (var el of keypressElements) {
            el.addEventListener('keypress', () => this.onKeypress(), false);
            el.addEventListener('keyup', () => this.onKeypress(), false);
            el.addEventListener('change', () => this.onKeypress(), false);
        }
    }


    /**
     * Handle click events
     *
     * @param  {Event} e
     * @return {undefined}
     */
    onClick(e) {
        var parent = e.target.parentNode.querySelector('.active');
        parent.classList.remove('active');
        e.target.classList.add('active');
        this.onKeypress();
    }


    /**
     * Handle keypress events
     *
     * @return {undefined}
     */
    onKeypress() {
        var startTime   = this.controls.querySelectorAll('input')[0].value,
            startHour   = this.controls.querySelectorAll('.active')[0].innerHTML,
            endTime     = this.controls.querySelectorAll('input')[1].value,
            endHour     = this.controls.querySelectorAll('.active')[1].innerHTML;

        // Update value
        var timeString = startTime + startHour + ' - ' + endTime + endHour;
        this.element.value = timeString;

        // Trigger change event
        var event = document.createEvent('HTMLEvents');
        event.initEvent('change', true, false);
        this.element.dispatchEvent(event);
    }


    /**
     * Add data to time elements
     *
     * @return {undefined}
     */
    insertExistingData() {
        var timeString = this.element.value.split(' - '),
            timeStringStart = timeString[0].substr(0, timeString[0].length - 2),
            timeStringEnd = timeString[1].substr(0, timeString[1].length - 2),
            timeStringStartHour = timeString[0].substr(timeString[0].length - 2),
            timeStringEndHour = timeString[1].substr(timeString[1].length - 2);

        var startTime = this.controls.querySelectorAll('input')[0],
            endTime = this.controls.querySelectorAll('input')[1],
            startHourButtons = Array.prototype.slice.call(this.controls.querySelectorAll('.control-group a')).splice(0, 2),
            endHourButtons = Array.prototype.slice.call(this.controls.querySelectorAll('.control-group a')).splice(2, 2);

        startTime.setAttribute('value', timeStringStart);
        endTime.setAttribute('value', timeStringEnd);

        for (var btn of this.controls.querySelectorAll('a.active')) {
            btn.classList.remove('active');
        }

        if (timeStringStartHour == 'AM') {
            startHourButtons[0].classList.add('active');
        } else {
            startHourButtons[1].classList.add('active');
        }

        if (timeStringEndHour == 'AM') {
            endHourButtons[0].classList.add('active');
        } else {
            endHourButtons[1].classList.add('active');
        }
    }
}


class DemDependency {
    constructor(container = null, disableOnly = false) {
        container = (container == null) ? '' : container;
        this.allFollowers = document.querySelectorAll(container + '[data-depends]');
        this.performerIds = [];
        this.disableOnly = disableOnly;
        this.supportedPerformerTypes = ['select', 'input'];

        this.registerPerformers(this.allFollowers);
        this.activateListeners(this.performerIds);
    }


    /**
     * Adds all elements being depended on to performers[]
     *
     * @param  {Array|NodeList} elements
     */
    registerPerformers(followers) {
        for (var el of followers) {
            var id = el.dataset.depends;

            if (!this.performerIds.includes(id)) {
                this.performerIds.push(id);
            }
        }
    }


    /**
     * Adds listeners to each performer (aka "depended on") element
     *
     * @param  {Array|NodeList} performers
     */
    activateListeners(performerIds) {
        for (var id of performerIds) {
            // Load performer
            var performer = this.loadPerformer(id);

            if (performer) {
                // Add onChange event listener
                performer.addEventListener('change', (e) => this.onChange(e.target), false);

                // Trigger listener once (so followers are hidden/shown when page is loaded)
                this.onChange(performer);
            }
        }
    }


    /**
     * Shows and hides elements following a performer
     *
     * @param {HtmlElement} performer
     */
    onChange(performer) {
        var followers = this.loadFollowers(performer.id),
            show = !(performer.value == 0),
            childFollowers = [];

        // Do any followers have children?
        for (var follower of followers) {
            var children = follower.querySelectorAll('select[id], input[id]');

            for (var child of children) {
                // Is this child also a performer?
                if (this.performerIds.includes(child.id)) {

                    // Should we involve this child's followers?
                    if (show ? child.value != 0 : true) {
                        var additionalFollowers = this.loadFollowers(child.id);

                        // Does this child performer have any followers?
                        if (additionalFollowers) {
                            childFollowers = childFollowers.concat(additionalFollowers);
                        }
                    }
                }
            }
        }

        followers = followers.concat(childFollowers);

        for (var el of followers) {
            if (this.disableOnly) {
                if (show) {
                    el.removeAttribute('disabled');
                    el.classList.remove('disabled');
                } else {
                    el.setAttribute('disabled', 'disabled');
                    el.classList.add('disabled');
                }
            } else {
                el.style.display = (show) ? null : 'none';
            }
        }
    }


    /**
     * Attempt to load a performer DOM element using id
     *
     * @param  {String} id
     * @return {HtmlElement|NULL}
     */
    loadPerformer(id) {
        try {
            var performer = document.querySelector('#' + id);

            if (typeof performer == 'undefined' || performer == null) {
                throw `#${id} could not be found`;
            }

            if (!this.supportedPerformerTypes.includes(performer.tagName.toLowerCase())) {
                throw `[data-depends] must point to an element that provide a boolean value (ex true/false or 1/0). \n\n<${performer.tagName.toLowerCase()}> elements are not supported.`;
            }
        } catch (e) {
            console.error(e);
            return null;
        }

        return performer;
    }


    loadFollowers(id) {
        try {
            var performerExists = (document.querySelector('#' + id).length > 0);

            if (!performerExists) {
                throw `A performer with the triggered id "#${id}" does not exist`;
            }

            var followers = Array.prototype.slice.call(document.querySelectorAll(`[data-depends="${id}"]`));
        } catch (e) {
            console.error(e);
            return null;
        }

        return followers;
    }
}


class DemGrow {
    constructor(element) {
        if (element.tagName != 'IMG') {
            console.error('DemGrow only accepts <img> elements');
            return false;
        }

        this.thumbnail = element;
        this.preview = element.nextElementSibling;
        this.isShown = false;

        if (!this.preview.querySelector('img')) {
            console.error('DemGrow requires both an <img> AND a neighboring <div> that contains a full-width preview img');
            console.warn('DemGrow Example \n\n <img src="someimg.png" style="max-width: 100px;">\n <div class="randomClass">\n \t<img src="someimg.png"> \n </div>');
            return false;
        }

        this.activateListeners();
    }

    /**
     * Add the same click listener to thumbnail and preview
     */
    activateListeners() {
        this.thumbnail.addEventListener('click', () => this.togglePreview(), false);
        this.preview.addEventListener('click', () => this.togglePreview(), false);
    }


    /**
     * Show or hide the preview element (toggles preview's "active" class)
     */
    togglePreview() {
        var prev = this.preview;

        if (!this.isShown) {
            // Hide
            prev.classList.add('active');
        } else {
            // Show
            prev.style.opacity = 0;

            // This timeout prevents wierd opacity flashing due to z-index conflicts
            setTimeout(() => {
                prev.style.opacity = null;
                prev.classList.remove('active');
            }, 400);
        }

        this.isShown = !this.isShown;
    }
}

class DemField {
    /**
     * Creates a DemField object
     *
     * @param {Number} labelKey
     * @param {Number} inputKey
     * @param {Boolean} positioning - Should a `pos` measurements node be added to the field?
     * @param {String} maskData
     * @param {Number} phoneLength
     * @return {DemField}
     */
    constructor(labelKey, inputKey, maskData = null, phoneLength = 0) {
        // Validating
        if (!labelKey || !inputKey) {
            console.error('DemField requires `labelKey` and `inputKey` arguments');
            return false;
        } else if (typeof labelKey != 'number' || typeof inputKey != 'number') {
            console.error('`labelKey` and `inputKey` must be an instance of {Number}');
            return false;
        }

        this.label = document.getElementById('builder_' + labelKey);
        this.prefix = document.getElementById('builder_placeholder_' + labelKey);
        this.input = document.getElementById('builder_' + inputKey);
        this.rendered = document.getElementById('builder_placeholder_' + inputKey);
        this.renderStatus = false;
        this.mask = maskData;
        this.phoneLength = phoneLength;

        this.pos = {
            left: this.input.style.left || 0,
            prefixTop: this.prefix.style.top || null,
            prefixLeft: this.prefix.style.top || null,
            top: this.input.style.top || 0,
        };

        this.input.addEventListener('keyup', () => this.shouldRender());
        this.input.addEventListener('blur', () => this.shouldRender());
        this.label.addEventListener('change', () => this.shouldRender());

        return this;
    }


    /**
     * Shows or hides a rendered element based on changes to its corresponding input
     *
     * @return {Boolean}
     */
    shouldRender() {
        var input = this.input,
            label = this.label,
            prefix = this.prefix,
            rendered = this.rendered;

        // Show or hide element
        if (
            label.value
            && input.value
            && label.value.length > 0
            && input.value.length > 0
            && DemField.isPhoneComplete(this)
        ) {
            if (!DemField.isLabelSelected(label)) {
                this.renderStatus = false;
                DemField.hide(rendered);
                DemField.hide(prefix);
            } else {
                DemField.show(rendered);
                DemField.show(prefix);
                this.renderStatus = true;
            }
        } else {
            DemField.hide(rendered);
            DemField.hide(prefix);
            this.renderStatus = false;
        }

        return this.renderStatus;
    }

    get builderPlaceholder() {
        return document.getElementById(this.input.id.replace('_', '_placeholder_'));
    }

    forceUpdatePlaceholder(value) {
        this.builderPlaceholder.querySelector('span').innerHTML = value;
    }

    static show(el) {
        el.style.opacity = null;
    }

    static hide(el) {
        el.style.opacity = 0;
    }


    /**
     * Is an index greater than zero selected for the given element?
     *
     * @param  {HTMLSelectElement}  el
     * @return {Boolean}
     */
    static isLabelSelected(el) {
        if (el.nodeName == 'SELECT') {
            if (el.hasOwnProperty('selectedIndex')) {
                return (el.selectedIndex > 0);
            } else if (el.hasOwnProperty('selectedOptions')) {
                return (el.selectedOptions.length > 0 && el.selectedOptions[0].hasAttribute('value'));
            }
        }

        return true;
    }


    /**
     * Checks for all necessary parameters for a phone number to render
     *
     * @param {DemField} field
     * @param {Number} length - expected position of last digit
     * @return boolean
     */
    static isPhoneComplete(field) {
        if (field.phoneLength) {
            var phoneNumber = field.input.value;
            var lastDigit = phoneNumber[field.phoneLength]; // [11]

            if (!lastDigit || lastDigit == '_' || phoneNumber.length == 0) {
                return false;
            }
        }

        return true;
    }


    /**
     * Disables inputs and erases rendered prefixes
     *
     * @param  {DemField} field
     * @param  {Boolean} dontRender
     * @return  {DemField}
     */
    static disableInput(field, dontRender = false) {
        var input = field.input;

        if (!input.getAttribute('disabled')) {
            [input, field.label].forEach(el => {
                el.setAttribute('disabled', 'disabled');
                el.value = null;
            });

            /* @todo Replace this with a native js solution */
            // jQuery(field.label).triggerHandler('change');

            var renderedElements = field.prefix.querySelectorAll('span');
            Array.from(renderedElements).forEach(el => {
                el.innerHTML = null;
            });

            if (!dontRender) {
                field.shouldRender();
            }
        }

        return field;
    }


    /**
     * Enables the given input
     *
     * @param  {DemField} field
     * @param  {Boolean} dontRender
     * @return  {DemField}
     */
    static enableInput(field, dontRender = false) {
        field.input.removeAttribute('disabled');
        field.label.removeAttribute('disabled');

        if (!dontRender) {
            field.shouldRender();
        }

        return field;
    }


    /**
     * Enable or disable inputs based on source render status
     *
     * @param  {DemField} source
     * @param  {DemField} follower
     * @return  {DemField}
     */
    static updateFollowers(source, follower) {
        if (source.renderStatus) {
            this.enableInput(follower);
        } else {
            this.disableInput(follower);
        }

        return source;
    }


    /**
     * Runs mask function
     *
     * @param  {DemField} field
     * @return  {DemField}
     */
    static applyInputMask(field) {
        if (field instanceof DemField) {
            if (field.mask instanceof Object) {
                jQuery(field.input).mask(
                    field.mask.value,
                    field.mask.options || null
                );
            } else if (typeof field.mask == 'string') {
                jQuery(field.input).mask(field.mask);
            } else {
                console.warn('Warning: DemField.applyInputMask() requires a valid DemField object with `input` and `mask` properties.');
            }
        } else {
            console.warn('DemField requires an instance of DemField as an argument');
        }

        if (field.input.value) {
            // Force update builder
            field.forceUpdatePlaceholder(field.input.value);
        }

        return field;
    }


    /**
     * Update the "top" css style when source is rendered
     *
     * @param  {DemField} source
     * @param  {DemField} follower
     * @return  {DemField}
     */
    static updateTopFollowers(source, follower) {
        if (source.pos && follower.pos) {
            var distance = (source.renderStatus) ? follower.pos.prefixTop : source.pos.prefixTop;

            follower.rendered.style.top = distance;
        } else {
            console.error('updateTopFollowers(source, follower): Both arguments must have a `pos` object property.');
        }

        return source;
    }
}
